import React from 'react';
import Emoji from 'a11y-react-emoji';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import WebPageSchema from '../components/WebPageSchema';
import { LinkedInLink, GitHubLink } from '../components/ExternalLink';

import * as PageStyles from './page.module.scss';

export default function AboutPage() {
  const pageMeta = {
    title: 'About',
    description: 'About Andy Nguyen, a web developer.',
    uri: 'about',
  };

  return (
    <Layout>
      <Seo pageMeta={pageMeta} />
      <WebPageSchema pageMeta={pageMeta} />
      <section aria-labelledby="about" className={PageStyles.introSection}>
        <h1 id="about">About</h1>
        <p>
          Thank you for visiting <Emoji symbol="🙏" />! I'm Andy, a web
          developer based out of Oklahoma, USA <Emoji symbol="🌪️" />.
        </p>
        <p>
          JavaScript is my preferred programming language, but I've also worked
          extensively with PHP <Emoji symbol="👨‍💻" />. Usually, I work on React
          apps, but I can also be seen creating APIs or configuring deployments.
          I'm a big fan of Jamstack architecture, and WordPress holds a special
          place in my heart <Emoji symbol="❤️" />. Check out my work experience
          on <LinkedInLink /> and/or code examples on <GitHubLink />!
        </p>
      </section>
      <section aria-labelledby="projects" className={PageStyles.listSection}>
        <h2 id="projects">Projects</h2>
        <ul>
          <li>
            <a
              href="https://github.com/AndyN9/payday-calendar-generator"
              target="_blank"
              rel="noopener noreferrer"
            >
              Payday Calendar Generator
            </a>{' '}
            - generate a payday calendar (iCal file) for different payroll
            periods
          </li>
          <li>
            <a
              href="https://github.com/AndyN9/personalfinance-prime-directive"
              target="_blank"
              rel="noopener noreferrer"
            >
              Personal Finance Prime Directive
            </a>{' '}
            - personal income spending flowchart
          </li>
        </ul>
      </section>
    </Layout>
  );
}
